//
// Plugins: Ekko Lightbox override
//

.ekko-lightbox-nav-overlay {
  a  {
    span {
      position: absolute;
      left: 0px;
      width: 30px;
      height: 100%;
      background: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    &:last-child {
      span {
        left: auto;
        right: 0;
      }
    }
  }
} 