.dz-container {
    background-color: #E7E8EB;
    border: 1px dashed #B4B8C4;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    .dz-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .gray {
            color: $gray-600;
        }
        .hint {
            font-size: .65rem;
        }
    }
}
.progress {
    height: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
.btn-dz {
    color: $gray-600;
    background-color: transparent;
    &--delete {
        color: $darkblue;
        &:hover {
            color: $gray-400;
        }
    }
    &--view {
        color: $darkblue;
        &:hover {
            color: $lightblue;
        }
    }
}