/* source-sans-pro-200 - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-200italic - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200italic.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200italic.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-200italic.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-300 - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-300italic - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300italic.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300italic.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-300italic.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-regular - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-regular.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-regular.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-regular.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-regular.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-regular.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-italic - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-italic.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-italic.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-600 - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-600italic - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600italic.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600italic.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-600italic.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700 - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-700italic - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700italic.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-700italic.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-900 - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
/* source-sans-pro-900italic - latin-ext_latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900italic.eot"); /* IE9 Compat Modes */
	src: local(""),
		url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900italic.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900italic.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900italic.woff")
			format("woff"),
		/* Modern Browsers */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900italic.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../../plugins/Source_Sans_Pro/source-sans-pro-v14-latin-ext_latin-900italic.svg#SourceSansPro")
			format("svg"); /* Legacy iOS */
}
