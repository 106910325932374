.content-wrapper.kanban {
	height: 1px;
	.custom-control-label::before {
		top: .18rem;
	}
	.custom-control-label::after {
		top: calc(.18rem + 2px);
	}
	.content {
		height: 100%;
		overflow-x: auto;
		overflow-y: hidden;

		.container,
		.container-fluid {
			display: flex;
			align-items: stretch;
			width: max-content;
			margin-left: 0;
		}
	}

	.content-header + .content {
		height: calc(100% - ((2 * 15px) + (1.8rem * #{$headings-line-height})));
	}

	.card {
		&.card-row {
			width: 310px;
			display: inline-block;
			margin: 0 0.5rem;
			overflow-y: hidden;
			background-color: #f3f3f3;
			border-radius: 6px;
			border: 0;
			box-shadow: none;

			> .card-header {
				margin-left: 0;
				border-bottom: 0;
				padding: 1.5rem 1.5rem;

				.card-title {
					font-size: 18px;
					font-weight: 700;
				}
			}

			> .card-body {
				overflow-y: auto;
				height: 100%;
				padding: 0 1.5rem;

				.card {
					border-radius: 6px;
					border: 1px solid #e7e7e7;
					box-shadow: 0 3px 6px 0 rgba(218, 218, 218, 0.5);
					text-decoration: none;
					padding: 10px;

					&:last-child {
						border-bottom-width: 1px;
					}

					.card-header {
						padding: 0.5rem 0.75rem;
					}

					.card-body {
						padding: 0.75rem;
					}

					.card-footer {
						background-color: transparent;
					}
				}
			}
		}
	}

	.btn-tool {
		&.btn-link {
			text-decoration: underline;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
