h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  color: $primary;
}

a {
  text-decoration: none;
  color: $lightblue;
  font-weight: 500;
  &:hover {
    color: $primary;
    text-decoration: underline;
  }
  &.btn {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &.card-link {
    display: block;
    text-decoration: none;
    color: $primary;
    transition: transform .2s ease-in-out;
    &:hover {
      text-decoration: none;
      transform: scale(.95);
    }
  }
}

p {
  color: $primary;
  font-weight: 500;
}
label,
.label {
  color: $primary;
  text-transform: uppercase;
  font-size: .8rem;
  margin-bottom: 0;
  word-break: break-all;

  &.nocase {
    text-transform: none;
    font-size: 1rem;
    font-weight: 500 !important;
  }
}

.underline {
  z-index: 1;
  position: relative;
  &::after {
    content: "";
    background-color: $lightblue;
    position: absolute;
    width: 108%;
    height: 15px;
    bottom: 0;
    left: -4%;
    z-index: -1;
  }
}
.tiny {
  font-size: .8rem;
}