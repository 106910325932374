//
// Component: Brand
//

.brand-link {
	$brand-link-padding-y: $navbar-brand-padding-y + $navbar-padding-y;
	display: flex;
	align-items: center;
	font-size: $navbar-brand-font-size;
	line-height: $line-height-lg;
	padding: 0;
	transition: width $transition-speed $transition-fn;
	white-space: nowrap;
	height: 50px;

	&:hover {
		color: $white;
		text-decoration: none;
	}

	.text-sm & {
		font-size: inherit;
	}

	[class*='sidebar-dark'] & {
		border-bottom: 1px solid lighten($dark, 10%);

		&,
		.pushmenu {
			color: rgba($white, 0.8);

			&:hover {
				color: $white;
			}
		}
	}

	[class*='sidebar-light'] & {
		//border-bottom: 1px solid $gray-300;

		&,
		.pushmenu {
			color: rgba($black, 0.8);

			&:hover {
				color: $black;
			}
		}
	}

	.pushmenu {
		margin-right: $sidebar-padding-x;
		font-size: $font-size-base;
	}

	.brand-link {
		padding: 0;
		border-bottom: none;
	}

	.brand-image {
		float: left;
		line-height: 0.8;
		margin-right: 30px;
		height: 50px;
		max-height: 50px;
		width: auto;
		transition: width 1s ease-in-out;
	}
	.brand-text {
		height: 25px;
		max-height: 25px;
		width: auto;
	}

	.brand-image-xs {
		float: left;
		line-height: 0.8;
		margin-top: -0.1rem;
		max-height: 33px;
		width: auto;
	}

	.brand-image-xl {
		line-height: 0.8;
		max-height: 40px;
		width: auto;

		&.single {
			margin-top: -0.3rem;
		}
	}

	&.text-sm,
	.text-sm & {
		.brand-image {
			height: 29px;
			margin-bottom: -0.25rem;
			margin-left: 0.95rem;
			margin-top: -0.25rem;
		}

		.brand-image-xs {
			margin-top: -0.2rem;
			max-height: 29px;
		}

		.brand-image-xl {
			margin-top: -0.225rem;
			max-height: 38px;
		}
	}
}

.sidebar-collapse {
	.brand-image {
		margin-right: 0;
		height: auto;
		max-height: 50px;
		width: 50px;
		transition: width 1s ease-in-out;
	}

	.brand-text {
		display: none;
	}
}
