//
// Component: Avatar
//

.avatar {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: #6a5acd;
	color: #fff;
	display: inline-flex;
	margin-left: 10px;
	justify-content: center;
	align-items: center;
	font-size: 1em;

	&--big {
		width: 105px;
		height: 105px;
		font-size: 3.5em;
	}
}
